<template>
<section class="">
        <div class="col-md-12 landing-page" v-for="(block, blockId) in blocks" :key="blockId">
          <!-- Carousel -->
          <div class="col-md-12" v-if="block.element == 'carousel'">
              <h3>{{block.element}}</h3>
              <carousel :slides="block.data.slides"/>
          </div>
          <div class="col-md-12" v-else-if="block.element == 'card'">
              <h3>{{block.element}}</h3>
              <product-card v-if="block.entitytype" :key="collectionComponentKey" :entitytype="block.entitytype" :scrollcards="scrollcards" :title="block.data.collection['card-title']" :price="block.data.collection['card-price']" :description="block.data.collection['card-description']" column="col-md-3" sectionTitle="Représentation de la collection"></product-card>
                <product-card
                v-else
                :card="block.data.card"
                :scrollcards="scrollcards"
                :key="individualCardComponentKey"
                title="card-title"
                description="card-description"
                sectionTitle="Représentation des cards individuels"
                v-on:edit="editCard"
                >
                </product-card>
            </div>
          <div class="col-md-12" v-else-if="block.element == 'text'">
              <div v-for="text in block.data.text" :key="text.id">
                  <heading v-if="text.element=='heading'" :text="text.data['heading-text']" :justify="text.data['heading-justify']"/>
                  <paragraph v-else-if="text.element=='paragraph'" :text="text.data['paragraph-text']" :justify="text.data['paragraph-justify']"/>
              </div>
          </div>
          <div class="col-md-12" v-else-if="block.element == 'card-title-description'">
              <h3>card title description</h3>
                  <card-title-description v-if="block.entitype" :entitytype="block.entitytype" :title="block.data['card-title']" :description="block.data['card-description']"></card-title-description>
                  <card-title-description v-else :card="block.data.card" title="card-title" description="card-description"></card-title-description>
          </div>
          <div v-else-if="block.element == 'horizontal-banner'">
              <h3>bannière horizontale</h3>
              <horizontal-banner :items="block.data['horizontal-banner']"></horizontal-banner>
          </div>
          <span v-else>Pas d'élément pour les cards</span>
        </div>
  </section>
</template>

<script>
import Carousel from './element/carousel.vue'
// import CardPagination from './pagination/card-pagination.vue'
import ProductCard from './element/product-card.vue'
import cardTitleDescription from './element/card-title-description.vue'
import Heading from './element/text/heading.vue'
import Paragraph from './element/text/paragraph.vue'
import HorizontalBanner from './element/horizontal-banner.vue'
// import axios from 'axios'
export default {
    name: 'section-preview',
    components: {
    Carousel,
    // CardPagination,
    ProductCard,
    cardTitleDescription,
    HorizontalBanner,
    Heading,
    Paragraph
  },
  data () {
    return {
        page : []
    }
  },
  props: {
    ulid: String,
    blocks: Array
  },
  methods:{
      // getSectionPage(ulid) {
      //   console.log(ulid)
      //     axios.get(`sections/pages/${ulid}`)
      //     .then(resGetSectionPage=> {
      //         console.log({resGetSectionPage})
      //         this.page = resGetSectionPage.data.result
      //         })
      //     .catch(errGetSectionPage=> console.log({errGetSectionPage}))
      // }
  },
  mounted () {
      // this.getSectionPage(this.ulid)
      //  axios.get(`sections/pages/${this.ulid}`)
      //     .then(resGetSectionPage=> {
      //         console.log({resGetSectionPage})
      //         this.page = resGetSectionPage.data.result
      //         })
      //     .catch(errGetSectionPage=> console.log({errGetSectionPage}))
  }
}
</script>
<style scoped>
.landing-page {
  zoom: 3; 
  transform: scale(0.5); 
  
  transform-origin: 0 0;
}
</style>