<template>
  <div class="d-flex" :class="position">
    <button
      type="button"
      class="btn"
      @click="gotTo(link)"
      :class="editableClass"
    >
      {{ text }}
    </button>
    <button
      v-if="editable"
      class="btn btn-sm btm-rounded btn-icon"
      v-b-modal="modal"
    >
      <i class="fa fa-pencil"></i>
    </button>
    <b-modal v-if="editable" :id="modal" ref="modal" title="Edition button">
      <form ref="form">
        <b-form-group
          label="Text"
          label-for="name-input"
          invalid-feedback="Text is required"
        >
          <b-form-input v-model="text" required></b-form-input>
        </b-form-group>
        <b-form-group
          label="Url"
          label-for="name-input"
          invalid-feedback="Url is required"
        >
          <b-form-input v-model="link" required></b-form-input>
          <div class="form-group">
            <label for="">Position</label>
            <select class="form-control" v-model="position">
              <option value="justify-content-start">left</option>
              <option value="justify-content-center">center</option>
              <option value="justify-content-end">right</option>
            </select>
          </div>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "advance-button",
  methods: {
    gotTo(link) {
      //redirection logic
      window.location = link;
      console.log(link);
    },
  },
  props: {
    modal: String,
    btn_text: {
      type: String,
      default: "some text",
    },
    btn_type: {
      type: String,
      default: "btn-outline",
    },
    btn_color: {
      type: String,
      default: "primary",
    },
    btn_rounded: {
      type: Boolean,
      default: false,
    },
    btn_block: {
      type: Boolean,
      default: false,
    },
    btn_size: {
      type: String,
      default: "btn-sm",
    },
    btn_position: {
      type: String,
      default: "justify-content-end",
    },
    btn_link: {
      type: String,
      default: "#",
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: this.btn_text,
      position: this.btn_position,
      link: this.btn_link,
    };
  },
  computed: {
    editableClass: function () {
      let full = "";
      full = this.btn_type + "-" + this.btn_color + " " + this.btn_size;
      if (this.btn_rounded) {
        full = full + " btn-rounded";
      }
      if (this.btn_block) {
        full = full + " btn-block";
      }
      return full;
    },
  },
};
</script>
<style scoped>
</style>