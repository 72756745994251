<template>
  <section class="carousel">
    <div v-if="editable" class="d-flex justify-content-end">
      <button class="btn btn-success btn-sm mb-2" @click="pusht">
        <i class="mdi mdi-plus-circle-multiple-outline"></i>
      </button>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-carousel
          id="carousel"
          controls
          indicators
          background="#ababab"
          :interval="3000"
          v-model="slide2"
        >
          <b-carousel-slide v-for="(item, itemID) in items" :key="itemID">
            <img
              slot="img"
              v-if="item['carousel-image']"
              :src="item['carousel-image']"
              alt="slide image"
            />
            <img
              slot="img"
              v-else
              :src="require('../../assets/images/import/add-picture.png')"
              alt="slide image"
            />
            <div v-if="editable">
              <!-- carousel edit image -->
              <add-image
                :modal="'addImage' + itemID + sectionTitle"
                @saveImage="saveImage(item, $event['image'])"
              />
            </div>
            <!--end carousel edit image -->

            <span class="crud-carousel">
              <i
                class="fa fa-trash mx-2 pointer"
                @click="deleteSlide(item)"
              ></i>
            </span>
            <!-- <heading :text="item['carousel-title'].data['heading-text']" :justify="item['carousel-title'].data['heading-justify']"/> -->
            <string-text
              v-for="(description, descriptionId) in item.text"
              :key="descriptionId"
              :items="description"
              :edit="false"
              :editable="editable"
              :description-index="item.text.indexOf(description)"
              @remove-text="item.text.splice(item.text.indexOf($event), 1)"
              @push-text="item.text.splice($event['index'], 0, $event['text'])"
              @typographie-text="textTypographie(description, $event)"
            >
            </string-text>
            <!-- <adbutton
              :editable="editable"
              :btn_link="item['carousel-button-link']"
              :btn_position="item['carousel-button-alignment']"
              :btn_text="item['carousel-button']"
              :modal="'modaladbutton' + sectionTitle"
            ></adbutton> -->

            <div class="d-flex" :class="item['carousel-button-alignment']">
              <button
                class="btn btn-primary"
                :href="item['carousel-button-link']"
                v-if="item['carousel-button']"
              >
                <a v-if="editable" href=""></a>{{ item["carousel-button"] }}
              </button>
            </div>
          </b-carousel-slide>
        </b-carousel>
      </div>
    </div>
  </section>
</template>

<script>
const axios = require("axios").default;
import stringText from "./string-text.vue";
import slide_element from "./json/carousel-slide.json";
import addImage from "../functions/add-image.vue";
// import adbutton from "../element/button.vue";

export default {
  name: "carousel",
  components: {
    stringText,
    addImage,
    // adbutton,
  },
  data() {
    return {
      slide2: 0,
      items: [],
      slidesel: slide_element,
    };
  },
  props: {
    entitytype: String,
    title: String,
    description: String,
    button: String,
    link: String,
    slides: Array,
    sectionTitle: String,
    buttonText: String,
    buttonAlignment: String,
    buttonLink: String,
    editable: Boolean,
  },
  methods: {
    textTypographie(elem, data) {
      if (data["type"] == "size") {
        elem.data["paragraph-font-size"] = data["value"];
      }
      if (data["type"] == "justify") {
        elem.data["paragraph-font-justify"] = data["value"];
      }
      if (data["type"] == "weight") {
        elem.data["paragraph-font-weight"] = data["value"];
      }
      if (data["type"] == "style") {
        elem.data["paragraph-font-style"] = data["value"];
      }
    },
    getEntitytype() {
      axios
        .get(`https://sites.comolapapaya.com/${this.entitytype}`)
        .then((resEntitytype) => {
          this.items = resEntitytype.data.result;
        })
        .catch((errEntitytype) => {
          console.log(errEntitytype);
        });
    },
    deleteSlide(item) {
      this.$swal({
        title: "Êtes-vous sûr?",
        text: "Voulez-vous supprimer ce slide?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, Supprimer",
        cancelButtonText: "Annuler",
      }).then((result) => {
        if (result.value) {
          console.log({ item });
          const indexOfItem = this.items.indexOf("item");
          this.items.splice(indexOfItem, 1);
          this.$swal("Supprimé!", "Le slide a bien été supprimé", "success");
        }
      });
    },
    pusht() {
      let event = this.slidesel;
      console.log(event);
      this.$emit("push-carousel-new-slide", event);
    },
    saveImage(item, image) {
      item["carousel-image"] = image;
      // console.log({item})
      // console.log({image})
    },
  },
  mounted() {
    this.entitytype ? this.getEntitytype() : (this.items = this.slides);
    console.log(this.slides);
  },
};
</script>

<style scoped>
img {
  max-width: 625px;
  height: 417px;
  object-fit: cover;
}
.crud-carousel {
  position: absolute;
  top: -120%;
  right: 0%;
}
</style>
