<template>
<section class="product-card">
    <div class="page-header">
        <h3 class="page-title">
        {{sectionTitle}}
        </h3>
    </div>
    <div class="row">
        <div class="card-columns">
            <div class="card"  v-for="(item, itemID) in items" :key="itemID">
              <img class="card-img-top" src="@/assets/images/product_images_2/thumb_image12.jpg" alt="Card image cap">
              <div class="card-body">
                <h4  class="card-title">{{item[title]}}</h4>
                <p class="card-text">{{item[description]}}</p>
              </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import axios from 'axios'
export default {
    name: 'card-title-description',
    components: {
    },
    data () {
        return {
            items: []
        }
    },
    props: {
        entitytype: String,
        card: Array,
        title: String,
        description: String,
        column: String,
        sectionTitle: String,
        showTitle: Boolean,
        showDescription: Boolean
    },
    methods: {
        getEntitytype () {
            if(this.entitytype) {
                axios.get(`https://sites.comolapapaya.com/${this.entitytype}`)
                .then ( resEntitytype => {
                    this.items= resEntitytype.data.result
                })
                .catch ( errEntitytype => {console.log(errEntitytype)})
            }else if(this.card){
                this.items = this.card
            }else{
                this.items=[]
            }
        }
    },
    mounted () {
        this.getEntitytype ()
    }
}
</script>

<style scoped>
.product-item{
    display: inline-block;
    text-decoration: none;
    height: auto; 
    width: 288px;
}
</style>