<template>
<section class="product-card">
    <div class="page-header">
        <h3 class="page-title">
        {{sectionTitle}}
        </h3>
    </div>
    <div class="row product-item-wrapper" >
        <div :class="{scrollcards: scrollcards}" :id="id">
            <div class="col-lg-4 col-md-6 col-sm-6 col-12 mx-2 product-item stretch-card" v-for="(item, itemID) in items" :key="itemID">
                <div class="card">
                    <div class="card-body">
                        <div class="product-img-outer">
                            <!-- <img class="product_image" v-if="item.linkedimagesurls" :src="item.linkedimagesurls['originalImage']" alt=""> -->
                            <img  class="product_image" :src="item['card-image-url']" alt="prduct image">
                        </div>
                        <p class="product-title">{{item[title]}}</p>
                        <p class="product-price" v-if="price">{{item[price]}} €</p>
                        <p class="product-description" v-if="description">{{item[description]}}</p>
                    </div>
                    <div class="card-footer" v-if="editable">
                        <button class="btn" @click="$emit('edit',item)"><i class="fa fa-pencil"></i></button>
                        <button class="btn" @click="deleteCard(item)"><i class="fa fa-trash-o"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import axios from 'axios'
export default {
    name: 'product-card',
    components: {

    },
    data () {
        return {
            items: []
        }
    },
    props: {
        id: String,
        scrollcards: Boolean,
        entitytype: String,
        card: Array,
        title: String,
        description:String,
        price: String,
        column: String,
        sectionTitle: String,
        editable: Boolean
    },
    methods: {
        getEntitytype () {
            if(this.entitytype) {
                axios.get(`https://sites.comolapapaya.com/${this.entitytype}`)
                .then ( resEntitytype => {
                    this.items= resEntitytype.data.result
                })
                .catch ( errEntitytype => {console.log(errEntitytype)})
            }else if(this.card){
                this.items = this.card
            }else{
                this.items=[]
            }
        },
        //scrollcards
        scrollright() {
            console.log(this.id);
            document.getElementById(`${this.id}`).scrollLeft += 1000;
        },
        scrollleft() {
            console.log(this.id);
            document.getElementById(`${this.id}`).scrollLeft -= 1000;
        },
        deleteCard(item) {
            let index = this.items.indexOf(item)
            console.log(index)
            this.items.splice(index, 1)
        }
        // edit(item) {
        //     this.$emit('edit-card', {
        //         item: item
        //     })
        // }
    },
    mounted () {
        this.getEntitytype ()
    }
}
</script>

<style scoped>
.product-item{
    display: inline-block;
    text-decoration: none;
    height: auto; 
    width: 288px;
}

/* scrollcards */
 .scrollcards {
  /* background-color: #fff; */
  overflow-x: auto;
  white-space: nowrap;
}

/* ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
} */

.scrollcards {
    scrollbar-width: thin
}

div.scrollcards .card {
    display: inline-block;
    /* padding: 14px; */
    text-decoration: none;
    height: auto; 
    width: 300px;
}


.carousel-control-prev-icon {
    background-image: url('../../assets/images/import/arrow-preview.png');
    width: 50px;
    height: 50px;
    margin: 0px 81px 0px -122px
    }
.carousel-control-next-icon {
    background-image: url('../../assets/images/import/arrow-next.png');
    width: 50px;
    height: 50px;
    margin: 0px -121px 0px 79px
    }

img {
    width: auto;
    height: 200px;
    object-fit: cover;
}
</style>