<template>
  <!-- à vérifier ny dashboard taloha fa nofafana fa misy erreur d'installation  -->
  <section >
    <!-- <div v-b-toggle href="#sidebar-right-g" @hover.prevent> -->
    <div @click="visible=true">
      <!-- <b-button v-b-toggle href="#sidebar-right-g" @click.prevent>Toggle Sidebar</b-button> -->
      <!-- <b-button v-b-toggle.sidebar-right-g>Toggle Sidebar</b-button> -->
      <div v-if="editor" >
        <bubble-menu class="bubble-menu" :tippy-options="{ duration: 100 }" :editor="editor">
          <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
            <i class="fa  fa-bold" ></i>
          </button>
          <button @click="editor.chain().focus().toggleItalic().run()"
            :class="{ 'is-active': editor.isActive('italic') }">
            <i class="fa  fa-italic" ></i>
          </button>
          <button @click="editor.chain().focus().toggleStrike().run()"
            :class="{ 'is-active': editor.isActive('strike') }">
            <i class="fa  fa-strikethrough" ></i>
          </button>
        </bubble-menu>
  
        <floating-menu class="floating-menu" :tippy-options="{ duration: 100 }" :editor="editor">
          <button @click="editor.chain().focus().setParagraph().run()"
            :class="{ 'is-active': editor.isActive('paragraph') }">
            <i class="fa  fa-paragraph"></i>
          </button>
          <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
            H1
          </button>
          <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
            H2
          </button>
          <!-- <button @click="editor.chain().focus().toggleBulletList().run()"
            :class="{ 'is-active': editor.isActive('bulletList') }">
            Bullet List
          </button> -->
          <hr /><br>
          <button @click="editor.chain().focus().setTextAlign('left').run()"
            :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
            <i class="fa  fa-align-left" ></i>
          </button>
          <button @click="editor.chain().focus().setTextAlign('center').run()"
            :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
            <i class="fa  fa-align-center" ></i>
          </button>
          <button @click="editor.chain().focus().setTextAlign('right').run()"
            :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
            <i class="fa  fa-align-right" ></i>
          </button>
          <button @click="editor.chain().focus().setTextAlign('justify').run()"
            :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }">
            <i class="fa  fa-align-justify" ></i>
          </button>
        </floating-menu>
      </div>
  
      <editor-content :editor="editor" />
      <!-- 
      <style-sidebar-g isa:block="value" key="1" :visible="visible">
        <template v-if="editor" #tt-editor>
          <div class="parameter-group">
            <div class="d-flex justify-content-center w-100">
              <button @click="editor.chain().focus().undo().run()">
              <i class="mdi mdi-undo"></i>
              </button>
              <button @click="editor.chain().focus().redo().run()">
              <i class="mdi mdi-redo"></i>
              </button>
            </div>
          </div>
            

          <div class="parameter-group">
            <p class="parameter-title">POLICE</p>
            <span class="line"></span>
            <div class=" border-top d-flex justify-content-center w-100" role="group" aria-label="Basic example">
              <button class="" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
                <i class="fa fa-bold" ></i>
              </button>
              <button class="" @click="editor.chain().focus().toggleItalic().run()"
                :class="{ 'is-active': editor.isActive('italic') }">
                <i class="fa  fa-italic" ></i>
              </button>
              <button class="" @click="editor.chain().focus().toggleStrike().run()"
                :class="{ 'is-active': editor.isActive('strike') }">
                <i class="fa  fa-strikethrough" ></i>
              </button>
            </div>
          </div>

          <div class="parameter-group">
            <p>ALIGNEMENT</p>
            <span class="line"></span>
            <div class="d-flex justify-content-center w-100">
              <button @click="editor.chain().focus().setTextAlign('left').run()"
                :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
                <i class="fa  fa-align-left" ></i>
              </button>
              <button @click="editor.chain().focus().setTextAlign('center').run()"
                :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
                <i class="fa  fa-align-center" ></i>
              </button>
              <button @click="editor.chain().focus().setTextAlign('right').run()"
                :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
                <i class="fa  fa-align-right" ></i>
              </button>
              <button @click="editor.chain().focus().setTextAlign('justify').run()"
                :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }">
                <i class="fa  fa-align-justify" ></i>
              </button>
            </div>
          </div>

          <div class="parameter-group">
            <p>Taille du texte</p>
            <span class="line"></span>
            <div class="d-flex justify-content-center w-100">
              <button @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
              <i class=" mdi mdi-format-paragraph"></i>
              </button>
              <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
              h1
              </button>
              <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
              h2
              </button>
              <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
              h3
              </button>
              <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
              h4
              </button>
               <button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
              h5
              </button>
              <button @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
              h6
              </button> 
            </div>
          </div>


          <button @click="editor.chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
          code
          </button>
          <button @click="editor.chain().focus().unsetAllMarks().run()">
          clear marks
          </button>
          <button @click="editor.chain().focus().clearNodes().run()">
          clear nodes
          </button>
          
          <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
          bullet list
          </button>
          <button @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
          ordered list
          </button>
          <button @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">
          code block
          </button>
          <button @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
          blockquote
          </button>
          <button @click="editor.chain().focus().setHorizontalRule().run()">
          horizontal rule
          </button>
          <button @click="editor.chain().focus().setHardBreak().run()">
          hard break
          </button> 
          
        </template>
      </style-sidebar-g>
       -->
    </div>
  </section>
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
// import styleSidebarG from '../style-sidebar-g.vue'
import {
  BubbleMenu,
  Editor,
  EditorContent,
  FloatingMenu,
} from '@tiptap/vue-2'
import Highlight from '@tiptap/extension-highlight'
import TextAlign from '@tiptap/extension-text-align'
export default {
  name: "tt-editor",
  components: {
    EditorContent,
    BubbleMenu,
    // styleSidebarG,
    FloatingMenu,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editor: null,
      visible:false
    }
  },
  methods: {
    testFocus(){
      alert('ok')
    }
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Highlight,
      ],

      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML())

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>
<style lang="scss">
// sidebar content style
.parameter-group{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top:1rem;
  
  p{
    margin-bottom: 0px;
  }
  button{
    width: 100%;
    color: #9c9ca4;
    background: #fff;
    border: #e2e2e2 1px solid;
    padding: 5px;
  }
  button:hover{
    background: #eeeeee;
  }
  button.is-active{
    background:#eeeeee;
  }
}

.line{
  width: 100%;
  height: 1px;
  background: #d7d7d7;
  margin-bottom:4px;
}



/* Basic editor styles */
.ProseMirror {
  >*+* {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }
}

.bubble-menu {
  display: flex;
  background-color: #0D0D0D;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    color: #FFF;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}

.floating-menu {
  display: flex;
  background-color: #0D0D0D10;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}

.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}
</style>
