<template>
  <section class="app-footer">
    <footer class="footer px-0">
      <section
        class="module parallax parallax-1 d-flex"
        :style="background"
      >
        <div class="container">
          <!-- <add-image
            v-if="editable"
            :modal="'changeImg' + sectionTitle"
            @saveImage="saveImage($event['image'])"
          /> -->
          <div class="banner-text">
            <tt-editor v-if="modeEdit" :value="text" @input="changeText" ></tt-editor>
            <div v-else v-html="text"></div>
            <!-- <stringtext
              v-for="(description, descriptionId) in text"
              :key="descriptionId"
              :items="description"
              :edit="false"
              :editable="editable"
              :description-index="text.indexOf(description)"
              @remove-text="text.splice(text.indexOf($event), 1)"
              @push-text="text.splice($event['index'], 0, $event['text'])"
              @typographie-text="textTypographie(description, $event)"
            >
            </stringtext> -->
            <adbutton
              :editable="editable"
              :btn_link="'http://google.com/'"
              :btn_position="'justify-content-center'"
              :btn_text="'horizontal banner'"
            ></adbutton>
          </div>
        </div>
      </section>
    </footer>
  </section>
</template>
  
<script>
// import stringtext from "../element/string-text.vue";
import adbutton from "../element/button.vue";
import ttEditor from './tt-editor.vue';
// import addImage from "../functions/add-image.vue";

export default {
  name: "horizontal-banner",
  components: {
    // stringtext,
    adbutton,
    ttEditor
    // addImage,
  },
  data() {
    return {
      getdescription: null,
    };
  },
  computed: {
    background(){
      return{ backgroundImage: 'url(' + this.image + ')' }
    }
  },
  watch: {
    justify(data) {
      console.log(data);
    },
  },
  props: {
    text: String,
    edit: Boolean,
    modeEdit:Boolean,
    image: {
      type:String,
      default:"https://be-monechapeebelle.comolapapaya.com/img/massage-1-6192149516648.webp"
    },
    editable: Boolean,
    sectionTitle: String,
  },
  methods: {
    //tiptap tt-editor
    changeText(payload){
        this.$emit('changeTextHorizontalBanner',payload)
    }
    // format(description) {
    //   let justify = description.data["paragraph-justify"];
    //   return justify;
    // },
    // saveImage(image) {
    //   this.$emit("change-image-hb", image);
    // },
    // typography(j, h) {
    //   return j + " " + h;
    // },
    // textTypographie(elem, data) {
    //   if (data["type"] == "size") {
    //     elem.data["paragraph-font-size"] = data["value"];
    //   }
    //   if (data["type"] == "justify") {
    //     elem.data["paragraph-font-justify"] = data["value"];
    //   }
    //   if (data["type"] == "weight") {
    //     elem.data["paragraph-font-weight"] = data["value"];
    //   }
    //   if (data["type"] == "style") {
    //     elem.data["paragraph-font-style"] = data["value"];
    //   }
    // },
  },
};
</script>
  
<style scoped>
.editing_text {
  display: none;
}
input {
  border: none;
  background: transparent;
  width: 100%;
}

.hide_element {
  display: none;
}
.footer a,
a:hover {
  color: white;
  text-decoration: none;
}
.banner-text {
  background: none;
  padding: 0rem 1rem;
  /* text-align: center;
      align-self: center; */
}
h2,
h3 {
  color: white;
}

.container {
  max-width: 960px;
  margin: 0 auto;
}

section.module:last-child {
  margin-bottom: 0;
}

section.module.content {
  padding: 40px 0;
}

section.module.parallax {
  height: 200px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

section.module.parallax h1 {
  color: rgba(255, 255, 255, 0.8);
  font-size: 48px;
  line-height: 600px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
section.module.parallax-1 {
  /* background-image: url('../../assets/images/import/gift.jpg')!important; */
  width: 100%;
}

@media all and (min-width: 600px) {
  section.module h2 {
    font-size: 42px;
  }
  section.module p {
    font-size: 20px;
  }
  section.module.parallax h1 {
    font-size: 96px;
  }
}
@media all and (min-width: 960px) {
  section.module.parallax h1 {
    font-size: 160px;
  }
}
</style>
  
  <!--<style scoped>
  footer {
      min-height: 220px;
    /* background: -webkit-gradient(linear, left top, left bottom, from(rgb(42, 42, 42)), color-stop(8%, rgb(28, 28, 28)), to(rgb(1, 0, 0))) !important; */
    background: url('./../assets/images/import/gift.jpg') center no-repeat!important;
  }
  
  .footer a, a:hover {
    color: white;
    text-decoration: none
  }
  .banner-text {
      background: none;
      padding: 0rem 1rem;
  }
  h2, h3{
      color: white;
  }
  </style>-->
  